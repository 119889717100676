import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import {imgClass, linkClass} from './index.module.less'

import Layout from '../components/Layout'
import PostLink from '../components/PostLink'

const IndexPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout metadata={data.site.siteMetadata} noNav>
      <div style={{ maxWidth: 600, marginBottom: '3rem' }}>
        <div className={imgClass}>
          {data.file && <Img fluid={data.file.childImageSharp.fluid} />}
        </div>
        <h1>Carson Covell</h1>
        <p>Hello! My name is Carson, thanks for stopping by.</p>
        <p>
          I'm a creative living in the mountains of Dillon, CO. I love building
          digital products. While I'm a front-end developer by day, I also
          pursue design, writing and photography in my free time.
        </p>
        <p>
          Outside of my work, you may find me at the local rock climbing gym,
          out camping, playing soccer or skiing with my family.
        </p>
        <p>
          Follow me on{' '}
          <a className={linkClass} href="https://twitter.com/carsoncovell">
            Twitter
          </a>{' '}
          or{' '}
          <a className={linkClass} href="https://instagram.com/carsoncovell">
            Instagram
          </a>
          .
        </p>
      </div>

      <div className="blog-posts" style={{ maxWidth: 600, marginBottom: 80 }}>
        <h2>Blog</h2>
        <div>
          {posts
            .filter(post => post.node.frontmatter.title.length > 0)
            .map(edge => (
              <PostLink key={edge.node.id} post={edge.node} />
            ))}
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "IMG_1087.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
        url
        description
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            image {
              childImageSharp {
                fluid(maxWidth: 660) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
