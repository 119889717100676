import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import {postLink, textArea, imageClass, titleClass, dateClass, arrow} from './PostLink.module.less'

const PostLink = ({
  post: {
    frontmatter: { date, title, path, image },
  },
}) => (
  <div>
    <Link className={postLink} to={path}>
      {image && (
        <div className={imageClass}>
          <Img fluid={image.childImageSharp.fluid} />
        </div>
      )}
      <div className={textArea}>
        <div>
          <h3 className={titleClass}>{title}</h3>
          <p className={dateClass}>{date}</p>
        </div>
        <div className={arrow}>→ </div>
      </div>
    </Link>
  </div>
)

export default PostLink
